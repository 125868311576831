// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IAppConfig } from '@tcc-mono/shared/app-config';

export const environment: IAppConfig = {
  version: '1.1.0',
  production: false,
  apiUrl: 'https://vdv-api-develop.tccapps.nl/',
  authUrl: 'https://vdv-api-develop.tccapps.nl/',
  appCode: 'VDV-WEB',
  tccCoreAuthentication: {
    clientId: '9a703ee4-c2c7-4ec9-939c-6a4e3c5b8673',
    redirectUrl: window.location.origin + '/auth/callback'
  },
  vatPercentage: 21,
  weideToeslagInclVatInEur: 0.1
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
